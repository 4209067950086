export const Bio = {
  name: "Ahmet Kabakoğlu",
  roles: [
    "Full Stack Developer",
    "Game Developer",
    "UI/UX Designer",
  ],
  description:
    "I am a motivated and versatile individual, always eager to take on new challenges. With a passion for learning I am dedicated to delivering high-quality results. With a positive attitude and a growth mindset, I am ready to make a meaningful contribution and achieve great things",
  github: "https://github.com/ahmetkabakoglu",
  resume:
    "",
  linkedin: "https://www.linkedin.com/in/ahmetkabakoglu/",
  twitter: "https://twitter.com/AhmetCodes",
  insta: "https://www.instagram.com/ahmet.codes/",
  dribbble: "https://dribbble.com/ahmetkabakoglu",
};

export const skills = [
  {
    title: "Frontend",
    skills: [
      {
        name: "HTML",
        image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
      },
      {
        name: "CSS",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
      },
      {
        name: "JavaScript",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
      },
      {
        name: "Bootstrap",
        image:
          "https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png",
      },
      {
        name: "Tailwind CSS",
        image:
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAC8UlEQVR4Ae2XNZTbQBCG5TBzUl4VZj6+cH8pw0xNqHN10L8w9MVVgSoce+2kCUMVxjbUJSLTb/uXNH4rHYRJ731vRyt5Z3ZIa+OPu/5fNUkrBNMbf5BSVaEwBnqDPhLOxaRh33wtVJ91xQnIJMwrmiHqKw2pSZmacsojQTNoAydJK1jJZwboRWiE/bWxpvKEGatRJQPi4CPIgxxHKX8C7Xh3YM01h97wjeDG7Iq1I2Ju+i80Xc4a1crsA/k0lWSBRVxBhs/y4D6oAsIIjRjo1dXuvR8f5MIZjgT3uiccym/ABP6+L4gJxX2qy5scFmpAbdKUyqu5YAfjvBhsAOkwI4jL8S2Y5OfF2bwhDBkH2iI9IJLoKFgt58Qi8QgjpLfeg/XIiyELrlvYnNUP903gBdjfVQgGgRmUg3qnzPk9gUItRFniGfIQvOT9zWJiB25ns6lN2sDfJWE4EloS9aW8SyiVCZmj7IRUy3QQ0uW4y8akazSAOpRNvXIMsXO63yS+J1aAN1KRRMw9BnO8JJfKe8kOVlteeEQJBVnRA7IjKq1ahoCt4Dxd/YG85NwmMKgahlNfkHClslClmG+KWoCLD/GVKptG2KIFl+egJNgAveSFVnZJaf0cuqcrF76hu8vuLxqRdrUQzscuF4Dqq7axEGghJJ4wnYkhFTmivLKUXWHQLq3JgOoUvUKPBNBT9IRsu8UXb3LRlyyV93oZAb3c9nRRojPAIMqRH539bApNoF8t3Mk4rxeGZLRwBHNx4X7ZrFaziXEu2oA2MM5f5G5eLjKJ7ZT1HWlEmu15Mdt1B+ervYRFf4k0YBjLopwgCa3JTBD17VCxbgQR9weDaokOgVb/AlkhVfzE5mVCCizi5cvp4iccn/IgNMqMMEAqv2pyzqw0IvGlOA4E7SHVIuWPIA4vYtfM/q86H+pGyCPWSMa5FZwkbaCZz/RzYcr8ynNhIuxcaPO+e4dSHGy/038BRbo6lquvOJZ/TWg4avxx1/+rAHVbhJLs9OHAAAAAAElFTkSuQmCC",
      },
      {
        name: "Material UI",
        image:
          "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/materialui/materialui-original.svg",
      },
      {
        name: "React Js",
        image:
          "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
      },
    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "C#",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/csharp/csharp-original.svg",
      },
     
      {
        name: "ASP.NET",
        image:
          " https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/dot-net/dot-net-original.svg",
      },
      {
        name: "Python",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
      },
      {
        name: "MSSQL",
        image:
          "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/microsoftsqlserver/microsoftsqlserver-original.svg",
      },
      {
        name: "MySQL",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg",
      },
      {
        name: "Node Js",
        image: "https://nodejs.org/static/images/logo.svg",
      },
      {
        name: "MongoDB",
        image:
          "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/mongodb/mongodb-original.svg",
      },
      {
        name: "Firebase",
        image: "https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg",
      },
    ],
  },
  {
    title: "Game Development",
    skills: [
      {
        name: "Unity",
        image:
          "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/unity/unity-original.svg",
      },
      {
        name: "C#",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/csharp/csharp-original.svg",
      },
      {
        name: "Python",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
      },{
        name: "Blender",
        image:
          "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/blender/blender-original.svg",
      },{
        name: "Aseprite",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Logo_Aseprite.svg/180px-Logo_Aseprite.svg.png",
      },
    ],
  },
  {
    title: "UI / UX Design",
    skills: [
      {
        name: "Figma",
        image:
          "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/figma/figma-original.svg",
      },
      {
        name: "Adobe XD",
        image:
          "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/xd/xd-original.svg",
      },
      {
        name: "Adobe Photoshop",
        image:
          "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/photoshop/photoshop-original.svg",
      },
      {
        name: "Adobe Illustrator",
        image:
          "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/illustrator/illustrator-plain.svg",
      },
    ]
  },
  {
    title: "Others",
    skills: [
      {
        name: "Git",
        image:
          "https://e7.pngegg.com/pngimages/713/558/png-clipart-computer-icons-pro-git-github-logo-text-logo-thumbnail.png",
      },
      {
        name: "GitHub",
        image:
          "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
      },
      {
        name: "Netlify",
        image:
          "https://seeklogo.com/images/N/netlify-logo-BD8F8A77E2-seeklogo.com.png",
      },
      {
        name: "VS Code",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/512px-Visual_Studio_Code_1.35_icon.svg.png?20210804221519",
      },
      {
        name: "Arduino",
        image:
          "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/arduino/arduino-original-wordmark.svg",
      },
    ],
  },
];

export const experiences = [
  {
    id: 0,
    img: "https://i.imgur.com/iCuRxmP.png",
    role: "Full Stack Web Developer Intern",
    company: "PROBEL",
    date: "Feb 2023 - Sep 2023",
    desc: "During my internship, I aimed to develop an Information Management System tailored to the needs of dietitians. The system aimed to facilitate treatment processes by enabling dietitians to securely and effectively manage patient data. By combining my technical skills with the requirements of the healthcare sector, I presented a solution that provided a user-friendly interface and robust database management. This experience not only enhanced my software development skills but also deepened my understanding of healthcare information systems and industrial applications. The success of this project serves as strong evidence of my ability to integrate my technical expertise with the requirements of the healthcare sector and my potential to make unique contributions to future projects.",
    skills: [
      "ASP.NET MVC",
      "C#",
      "SQL Server",
      "HTML",
      "CSS",
      "JavaScript",
      "Material UI",
    ],
    //doc: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/Screenshot%20from%202024-01-25%2022-38-31.png?alt=media&token=2785903f-1a4e-41f5-afd2-6adcfe56d058",
  },
  {
    id: 1,
    img: "https://i.imgur.com/3YNvV7O.png",
    role: "Mobile Game Developer",
    company: "TÜBİTAK 2209/A Student Project",
    date: "Nov 2022 - ... ",
    desc: "Development of the project titled 'Developing a Mobile Game to Improve the Problem Solving and Management Skills of Primary and Secondary School Students: Factory Simulation Example', which was entitled to receive support within the scope of the TÜBİTAK 2209-A University Students Research Projects Support Program.",
    skills: [
      "Unity",
      "C#",
      "Blender",
      "Aseprite",
      "Photoshop",
      "MySQL",
      "Firebase",
    ],
    //doc: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/1696514649120.jpeg?alt=media&token=e7f6757b-edfa-4138-a692-d6709eeef3e2",
  },
  {
    id: 2,
    img: "https://i.imgur.com/MEZjmGW_d.jpg",
    role: "IT Intern",
    company: "Ödemiş Belediyesi",
    date: " Jul 2018 - Agu 2018",
    desc: "Throughout my summer internship at the Ödemiş Municipality Information Technology Department, I gained valuable experience in various areas. In terms of network management, I actively participated in tasks such as setting up, configuring, and maintaining network infrastructure. Regarding server management, I developed competence in server installation, configuration, and performance monitoring. In the field of database management, I acquired experience in tasks such as database creation, administration, and updates. Regarding security management, I became knowledgeable about implementing necessary measures to ensure the security of computer systems and networks. Additionally, I effectively provided technical support and resolved issues for users. This internship experience not only enhanced my skills in computer programming but also provided valuable insights into working in a real-world professional environment.",
    skills: [
      "Network Management",
      "Server Management",
      "Database Management",
      "Security Management",
      "Support and Technical Assistance"
    ],
    // doc: "https://firebasestorage.googleapis.com/v0/b/buckoid-917cf.appspot.com/o/WhatsApp%20Image%202023-05-05%20at%2012.07.39%20AM.jpeg?alt=media&token=9f0e1648-568b-422d-bd0b-1f125f722245",
  },
];

export const education = [
  {
    id: 0,
    img: "https://i.imgur.com/zGPz1Zr.png",
    school: "Muğla Sıtkı Koçman University, Muğla",
    date: "Oct 2020 - Nov 2023",
    grade: "3.1 GPA",
    desc: "I completed my a Bachelor's degree in Information Systems Engineering at Faculty of Technology, Muğla Sıtkı Koçman University. I have a GPA of 3.1. I have taken courses in Data Structures, Algorithms, Object-Oriented Programming, Database Management Systems, Operating Systems, and Computer Networks and more.",
    degree: "Bachelor Degree, Information Systems Engineering",
  },
  {
    id: 1,
    img: "https://i.imgur.com/hkpeZdx.png",
    school: "Kocaeli University, Kocaeli",
    date: "Sep 2017 - Jun 2019",
    grade: "2.79 GPA",
    desc: "I completed my a Associate degree in Computer Programming at Kocaeli Vocational School, Kocaeli University. I have a GPA of 2.79. I have taken courses in  Algorithms, Computer Basics, Data Structures, Database Management Systems and more.",
    degree: "Associate Degree, Computer Programming",
  },
  {
    id: 2,
    img: "https://i.imgur.com/99cTZNv.png",
    school: "Hulusi Uçaçelik Anatolian High School, İzmir",
    date: "Sep 2012 - Jun 2016",
    grade: "2.69 GPA",
    desc: "I completed my high school education at Hulusi Uçaçelik Anatolian High School on Ödemiş/İzmir, where I studied Maths and Science.",
    degree: "High School Graduate, Department of Maths and Science",
  },
];

export const projects = [
  
];

export const TimeLineData = [
  { year: 2017, text: "Started my journey" },
  { year: 2018, text: "Worked as a freelance developer" },
  { year: 2019, text: "Founded JavaScript Mastery" },
  { year: 2020, text: "Shared my projects with the world" },
  { year: 2021, text: "Started my own platform" },
];
